import introJs from 'intro.js';
import Http from '@/shared/http-config'
import store from '@/Store'

/**
 * To set options, or use other functions add:
 * import introJs from '@/shared/intro.js';
 *
 * To programmatic navigate through the tour you can use:
 * introJs.nextStep();
 */

let intro = introJs()
  .setOption('nextLabel', ' Weiter > ')
  .setOption('prevLabel', ' < Zurück ')
  .setOption('skipLabel', 'X')
  .setOption('doneLabel', ' Fertig ')
  .setOption('exitOnEsc', false)
  .setOption('showStepNumbers', false)
  .setOption('showBullets', true)
  .setOption('buttonClass', 'btn btn-primary')
  .setOption('exitOnOverlayClick', false)
  .setOption('overlayOpacity', 0.5);

intro.onchange(function(targetElement) {
  // add class to body to prevent invisible tour elements
  if(intro._currentStep === 0){
    document.querySelector('body').classList.add('introJs-active');
  }
  // check if all steps are availible
  intro._options.steps.forEach(function (step, key) {
    if(step.element){
      intro._introItems[key].element = document.querySelector(step.element);
      intro._introItems[key].position = step.position ? step.position : 'bottom';
    }
  });

  if(targetElement === null){
    if(intro._direction === 'forward'){
      intro.nextStep();
    }
    else{
      intro.previousStep();
    }
  }
});

intro.onexit(function() {
  // post tour on exit
  if(intro.alias && intro.alias.length > 0){
    Http.request('POST', '/user/tour', {alias: intro.alias}, false).then(() => {
      store.dispatch('fetchUser', true);
    });
  }
  if(intro._currentStep < (intro._introItems.length - 1)){
    store.dispatch('throwErrorMessage', 'Du beendest vorzeitig den Einführungswizard.<br>Du kannst diesen jederzeit, in der Ecke oben rechts über das &quot;?&quot; erneut durchlaufen.');
  }
  intro.alias = null;
  intro._currentStep = undefined;
  // remove class from body on tour exit
  document.querySelector('body').classList.remove('introJs-active');
});

intro.firstLoad = (function (alias, force = false) {
  intro.alias = alias;
  if(!store.getters.isLoading && document.querySelector('.c-body') !== null){
    let user = store.getters.getUser;
    if(user.tours.indexOf(alias) === -1 || force){
      intro.start();
    }
    else {
      intro.setOption('exitOnEsc', true);
      intro.setOption('exitOnOverlayClick', true);
    }
  }
  else {
    setTimeout(function () {
      intro.firstLoad(intro.alias);
    }, 500)
  }
});

export default intro;
